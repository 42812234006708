import { Injectable, inject } from '@angular/core';
import { Observable, map, shareReplay, combineLatest, BehaviorSubject } from 'rxjs';

import { UserRole } from '../enums/user-role';
import { OrganizationUserProfilePermission } from '../models/organization-user-permission';
import { filterNull } from '../utils/rxjs/filter-null';

import { UserAuthService } from './user-auth.service';

/**
 * User admin permissions for current organization.
 * This enum is just a helper to distinguish three possible cases:
 * - User is GlobalAdmin.
 * - User is not GlobalAdmin but has OrganizationAdmin role in the current organization.
 * - User doesn't have any admin permissions in the current organization.
 */
export enum CurrentOrganizationAdminPermission {
  GlobalAdmin = 'GlobalAdmin',
  OrganizationAdmin = 'OrganizationAdmin',
  None = 'None',
}

/** Current organization service. */
@Injectable({
  providedIn: 'root',
})
export class CurrentOrganizationService {
  private readonly userService = inject(UserAuthService);

  private readonly selectedOrganizationId$ = new BehaviorSubject<number | null>(null);

  /** Organizations. */
  public readonly organizationsList$ = this.createOrganizationListStream();

  /** List of allowed organizations for user. */
  public readonly allowedOrganizationsList$ = this.createAllowedOrganizationListStream();

  /** Current selected organization. */
  public readonly organization$ = this.createSelectedOrganizationStream();

  /** Whether user is organization admin. */
  public readonly userAdminPermission$ = this.createUserAdminPermissionStream();

  /**
   * Set selected organization.
   * If organization with passed id not found then select first organization.
   * @param organizationId Organization id.
   */
  public setSelectedOrganization(organizationId: number): void {
    this.selectedOrganizationId$.next(organizationId);
  }

  private createSelectedOrganizationStream(): Observable<OrganizationUserProfilePermission | null> {
    return combineLatest([
      this.organizationsList$,
      this.selectedOrganizationId$,
      this.allowedOrganizationsList$,
    ]).pipe(
      map(([orgs, selectedId, allowedOrganizationsList]) => {
        if (selectedId === null) {
          return allowedOrganizationsList.at(0) ?? null;
        }
        return orgs.find(org => org.id === selectedId) ?? allowedOrganizationsList.at(0) ?? null;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createOrganizationListStream(): Observable<readonly OrganizationUserProfilePermission[]> {
    return this.userService.currentUser$.pipe(
      filterNull(),
      map(user => user.organizations),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createAllowedOrganizationListStream(): Observable<readonly OrganizationUserProfilePermission[]> {
    const accessRoles = [UserRole.OrganizationAdmin, UserRole.UserAdmin, UserRole.Developer];
    return combineLatest([
      this.userService.currentUser$,
      this.organizationsList$,
    ]).pipe(
      map(([currentUser, organizations]) => {
        if (currentUser?.isGlobalAdmin) {
          return organizations;
        }

        return organizations.filter(
          org => org.roles.some(role => accessRoles.includes(role)),
        );
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createUserAdminPermissionStream(): Observable<CurrentOrganizationAdminPermission> {
    return combineLatest([
      this.organization$,
      this.userService.currentUser$.pipe(filterNull()),
    ]).pipe(
      map(([organization, currentUser]) => {
        if (currentUser.isGlobalAdmin) {
          return CurrentOrganizationAdminPermission.GlobalAdmin;
        }

        if (organization?.roles.includes(UserRole.OrganizationAdmin)) {
          return CurrentOrganizationAdminPermission.OrganizationAdmin;
        }

        return CurrentOrganizationAdminPermission.None;
      }),
    );
  }
}
